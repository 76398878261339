import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/global/Layout'
import Banner from '../components/page-content/Banner'
import SideNav from '../components/page-content/SideNav'
import Seo from '../components/global/Seo'
import { stripTags } from '../utils/misc'

const PageContentTemplate = ({ data }) => {
  const content = data.current.contentPageLeaf.content
  return (
    <Layout>
      <Seo
        title={data.current.title}
        description={stripTags(content).slice(0, 250).split('.')[0]}
      />
      <Banner
        title={data.root.title}
        description={data.root.contentPageRoot.description}
      />
      <div className="container max-w-5xl pt-8 pb-12 md:pt-12 md:pb-20">
        <div className="md:grid md:grid-cols-4 md:gap-x-12 space-y-8 md:space-y-0">
          <div>
            <SideNav {...data} />
          </div>
          <div className="col-span-3">
            <h2 className="text-4xl leading-10 text-blue-600">
              {data.current.title}
            </h2>
            <div className="py-5">
              <hr className="border-gray-200" />
            </div>
            <div
              className="prose"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageContentTemplate

export const query = graphql`
  query PageContentQuery($id: String!, $rootId: String!) {
    current: wpPage(id: { eq: $id }) {
      id
      title
      contentPageLeaf {
        content
      }
    }
    root: wpPage(id: { eq: $rootId }) {
      id
      title
      uri
      contentPageLeaf {
        content
      }
      contentPageRoot {
        description
      }
      wpChildren {
        nodes {
          nodeType
          ... on WpPage {
            id
            title
            uri
            contentPageLeaf {
              content
            }
            wpChildren {
              nodes {
                nodeType
                ... on WpPage {
                  id
                  title
                  uri
                  contentPageLeaf {
                    content
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
