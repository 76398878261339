import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { classNames } from '../../../utils/misc'
import CaretRightIcon from '../../../assets/images/svgs/caret-right.svg'

const parentStates = {}

const buildNavItem = (pageNode, currentNode, parent = null) => {
  const navItem = {
    id: pageNode.id,
    parent: parent,
    title: pageNode.title,
    type: pageNode.wpChildren?.nodes.length > 0 ? 'parent' : 'page',
  }
  if (navItem.type === 'page') {
    navItem.uri = pageNode.uri
    if (pageNode.id === currentNode.id) {
      navItem.current = true
      let current = navItem
      while (current.parent !== null) {
        current = current.parent
        current.containsCurrent = true
      }
    } else {
      navItem.current = false
    }
  } else {
    navItem.containsCurrent = false
    navItem.children = pageNode.wpChildren.nodes.map(child => {
      return buildNavItem(child, currentNode, navItem)
    })
  }
  return navItem
}

const SideNav = ({ root, current }) => {
  const navItems = root.wpChildren.nodes.map(child => {
    return buildNavItem(child, current)
  })
  return (
    <nav>
      <NavList navItems={navItems} />
    </nav>
  )
}

export default SideNav

const sharedNavItemClasses =
  'block w-full py-2 font-medium text-left rounded text-blue-600'

const NavList = ({ navItems, depth = 0 }) => {
  return (
    <ul className={classNames('space-y-1', depth > 0 && 'mt-1')}>
      {navItems.map(item => (
        <React.Fragment key={item.id}>
          {item.type === 'page' && <NavItemPage item={item} depth={depth} />}
          {item.type === 'parent' && (
            <NavItemParent item={item} depth={depth} />
          )}
        </React.Fragment>
      ))}
    </ul>
  )
}

const pageDepthClasses = {
  0: 'pl-6',
  1: 'pl-8',
  2: 'pl-10',
  3: 'pl-12',
}

const NavItemPage = ({ item, depth }) => {
  return (
    <li>
      <Link
        className={classNames(
          sharedNavItemClasses,
          pageDepthClasses[depth],
          item.current ? 'bg-blue-600 text-white' : 'hover:bg-blue-50',
          ''
        )}
        to={item.uri}
        state={{}}
      >
        {item.title}
      </Link>
    </li>
  )
}

const parentDepthClasses = {
  0: 'pl-2',
  1: 'pl-4',
  2: 'pl-6',
  3: 'pl-8',
}

const NavItemParent = ({ item, depth }) => {
  const [open, setOpen] = useState(item.containsCurrent)
  useEffect(() => {
    parentStates[item.id] = open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  return (
    <li>
      <button
        className={classNames(
          sharedNavItemClasses,
          parentDepthClasses[depth],
          // item.containsCurrent && 'bg-blue-600 text-white',
          'flex items-center space-x-2 hover:bg-blue-50'
        )}
        onClick={() => setOpen(!open)}
      >
        <CaretRightIcon
          className={classNames(
            'w-2 h-2 transition-transform ',
            open && 'transform rotate-90',
            'text-gray-300'
          )}
        />
        <span>{item.title}</span>
      </button>
      {open && <NavList navItems={item.children} depth={depth + 1} />}
    </li>
  )
}
