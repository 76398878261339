import React from 'react'

const Banner = ({ title, description }) => {
  return (
    <section id="content-banner">
      <div className="bg-blue-600">
        <div className="container py-12 sm:pt-14 sm:pb-16">
          <h1 className="text-6xl font-extrabold tracking-tight text-white">
            {title}
          </h1>
          <p className="mt-5 text-xl leading-7 text-gray-100">{description}</p>
        </div>
      </div>
    </section>
  )
}

export default Banner
